.carouselWrapper {
	:global(.slick-track) {
		display: flex;
	}
	:global(.slick-slide) {
		display: flex;
		height: auto;
		> div {
			height: 100%;
		}
	}
	:global(.slick-list .slick-slide) {
		pointer-events: all;
	}
	position: relative;
	height: 100%;
	.prev,
	.next {
		position: absolute;
		top: 50%;
		z-index: 2;
		cursor: pointer;
	}

	.prevFade {
		position: absolute;
		top: 0;
		height: 100%;
		width: 20%;
		content: '';
		background: linear-gradient(
			to right,
			#ffffff 0%,
			rgba(255, 255, 255, 0) 100%
		);
		svg {
			position: absolute;
			left: 0;
			top: 50%;
		}
	}

	.next {
		right: 8px;
	}

	.nextFade {
		position: absolute;
		bottom: 0;
		right: 0;
		top: 0;
		height: 100%;
		width: 20%;
		content: '';
		background: linear-gradient(
			to left,
			#ffffff 0%,
			rgba(255, 255, 255, 0) 100%
		);
		svg {
			position: absolute;
			right: 0;
			top: 50%;
		}
	}
}
