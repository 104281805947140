.progress {
	:global(.ant-progress) {
		&.hidePercent {
			color: #000;
			:global(.ant-progress-outer) {
				padding: 0;
			}
			:global(.ant-progress-text) {
				display: none;
			}
		}
	}
}
