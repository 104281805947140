@import '../../../theming/_init';

.select {
	&:global(.ant-select-focused) {
		&:global(.ant-select-show-search) {
			:global(.ant-select-selection-item) {
				@apply tw-text-gray-400;
			}
		}
	}
	&.activeSelect {
		height: 40px;
		:global {
			div.ant-select-selector {
				background-color: #fff1e1 !important;
				border: 1px solid #fec48c;
				@apply tw-h-10;
				.ant-select-selection-search {
					input {
						@apply tw-h-10;
					}
				}
			}
		}
		:global(.ant-select-selector) {
			:global(.ant-select-selection-item) {
				line-height: 40px; // tailwind not there
			}
		}
	}
	&.normal {
		height: 40px;
		:global {
			div.ant-select-selector {
				height: 40px;
				.ant-select-selection-search {
					input {
						@apply tw-h-10;
					}
				}
			}
		}
		:global(.ant-select-selector) {
			:global(.ant-select-selection-item) {
				line-height: 40px;
			}
		}
	}
	&.small {
		height: 32px;
		:global {
			div.ant-select-selector {
				@apply tw-h-8;
			}
		}
	}
	:global {
		div.ant-select-selector {
			@apply tw-rounded-md;
			.ant-select-selection-placeholder {
				@apply tw-text-color-text-subtle tw-flex tw-items-center;
			}
			&:after {
				color: red;
			}
		}
	}
	:global {
		.ant-select-arrow {
			color: red;
			display: flex;
			align-items: center;
			// svg {
			// 	margin-top: 4px;
			// }
		}
	}
}
.dropdown {
	width: auto !important;
}
