@import 'theming/_variables.scss';

.label {
	@apply tw-leading-[18px] tw-text-[13px] tw-text-color-text-subtle;
}

.slider {
	&:global(.ant-slider) {
		:global(.ant-slider-handle) {
			&::after {
				box-shadow: 0 0 0 2px $color-orange-500;
				// @apply tw-h-4;
				// @apply tw-w-4;
				// top: -3px;
				transform: scale(1.25);
			}
			&::before {
				content: unset;
			}
			&:hover::after,
			&:active::after {
				// height: 10px;
				// width: 10px;
				inset-inline-start: -1px;
				inset-block-start: -1px;
			}
		}
		:global(.ant-slider-track) {
			@apply tw-bg-color-orange-500;
			height: 2px;
		}
	}
}
