:global(.ant-switch.ant-switch-small) {
	height: 12px;
	min-width: 24px;
	&:global(.ant-switch-checked) {
		:global(.ant-switch-handle) {
			inset-inline-start: calc(100% - 10px);
		}
	}
	:global(.ant-switch-handle) {
		height: 8px;
		width: 8px;
	}
}
