.tabs {
	@apply tw-max-w-full;
	:global(.ant-timeline-item .ant-timeline-item-last) {
		padding-bottom: 0px !important;
	}

	li:last-child {
		@apply tw-pb-0;
	}

	&.fullWidth {
		:global(.ant-tabs-nav-list) {
			@apply tw-w-full;
		}
		:global(.ant-tabs-tab) {
			@apply tw-flex-1;
			@apply tw-justify-center;
		}
	}

	:global(.ant-tabs-tab) {
		@apply tw-text-color-black;

		&:global(.ant-tabs-tab-active .ant-tabs-tab-btn) {
			@apply tw-text-color-text-primary;
		}
	}
	:global(.ant-tabs-ink-bar) {
		@apply tw-bg-color-text-primary;
	}
}
