.steps {
	&:global(.ant-steps) {
		:global(.ant-steps-item-tail) {
			height: 100% !important;
			padding: 0 !important;
			width: 2px !important;
			left: 8px !important;
			&::after {
				background-color: #e5e7eb !important;
			}
		}
	}
}
.stepIcon {
	// @apply tw-border tw-border-solid tw-border-gray-300
	// border: 1px solid #9ca3af;
	@apply tw-h-4 tw-w-4 tw-bg-white tw-border tw-border-solid tw-border-gray-300 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-p-1;
	&.doneIcon {
		@apply tw-bg-gray-500;
	}
}
