.carousel {
	@apply tw-h-full;
	@apply tw-relative;
	:global(.carousel-root),
	:global(.carousel),
	:global(.slider-wrapper),
	:global(.slider),
	img {
		@apply tw-h-full;
		@apply tw-object-cover;
	}
	&.dotShadow {
		:global(.carousel-root) {
			:global(.control-dots) {
				background-image: linear-gradient(
					180deg,
					rgba(0, 0, 0, 0) 0%,
					#000000 100%
				);
				margin: 0;
				padding-bottom: 10px;
				padding-top: 10px;
			}
		}
	}
	:global(.carousel-root) {
		:global(.carousel) {
			:global(.dot) {
				@apply tw-w-1 tw-h-1;
				@apply tw-rounded-lg;
				@apply tw-my-0 tw-mx-1;
				@apply tw-opacity-70;
				&:global(.selected) {
					@apply tw-w-2 tw-h-1;
					@apply tw-bg-color-gray-50;
					@apply tw-rounded-lg;
				}
			}
		}
	}
}
