@import '../../../theming/_init';

.input {
	padding: 8px 12px;
	&.small {
		height: 32px;
	}
	&.normal {
		height: 40px;
	}
	::-webkit-input-placeholder,
	:-moz-placeholder,
	::-moz-placeholder {
		color: white;
	}
}
input.input::-webkit-input-placeholder {
	color: $black400;
}
input.input:-moz-placeholder {
	color: $black400;
}
input.input::-moz-placeholder {
	color: $black400;
}
