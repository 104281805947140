/* -------------- Colors -------------*/

$primary: #e95d25;
$bodyTextSecondary: #636d85;
$black400: #9ca3af;
$black500: #6b7280;
$black700: #374151;
$blackSubtle: #363e4f;
$black900: #111827;

/* -------------- Font Size -------------*/

$textxs: 0.75rem;
$textsm: 0.875rem;
$textmd: 1rem;
$textlg: 1.125rem;
$textxl: 1.25rem;
$text2xl: 1.5rem;
$text3xl: 1.875rem;
$text4xl: 2.25rem;
$text5xl: 3rem;
$text6xl: 4rem;

/* -------------- Font Weight -------------*/

$light: 300;
$normal: 400;
$semibold: 500;
$bold: 600;

/* -------------- Shadows -------------*/

$shadow100: 0px 1px 0px rgba(17, 24, 39, 0.1); ;
