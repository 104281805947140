.backButton {
	border: 1px solid #e4e4e7;
	padding: 0.5rem 1rem;
	border-radius: 20px;
	background-color: white;
	position: fixed;
	left: 64%;
	height: 40px;
	width: 130px;
	transform: translate(-50%, -50%);
	// bottom: 1rem;
	-webkit-box-shadow: 0px 10px 20px -11px rgba(36, 36, 36, 1);
	-moz-box-shadow: 0px 10px 20px -11px rgba(36, 36, 36, 1);
	box-shadow: 0px 10px 20px -11px rgba(36, 36, 36, 1);
	z-index: 50;
	cursor: pointer;

	@media screen and (max-width: 1200px) {
		left: 50%;
		bottom: 64px;
	}
}
