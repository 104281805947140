.skeleton {
	&:global(.ant-skeleton) {
		margin-right: 5px;
		:global(.ant-skeleton-content) {
			position: relative;
		}
		:global(.ant-skeleton-title) {
			border-radius: 4px;
			margin: 0;
			height: 100%;
			position: absolute;
		}
	}
}
