@import 'theming/_init';

.navItem {
	&:global(.ant-btn-default) {
		padding: 0px 8px;
		width: 100%;
		background: #ffffff;
		height: 40px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		border-radius: 8px;
		border: none;
		color: $black900;
		box-shadow: none;
		margin-bottom: 5px;
		&:hover {
			background: #f1f3f5;
			color: #363e4f;
		}
		&.active {
			background: #fff1e1;
			color: #ec5d25 !important;
		}
		&:focus-visible {
			outline: 0;
		}
		&.normal {
			height: 40px;
		}
		&.small {
			height: 32px;
		}
		&::after {
			display: none;
		}
	}
}
