.AsyncSelect {
	width: 240px;
	&:global(.ant-select) {
		:global(div.ant-select-selector) {
			height: 40px;
			display: flex;
			align-items: center;
			:global(.ant-select-selection-search) {
				display: flex;
				align-items: center;
				input {
					height: 40px;
				}
			}
		}
	}
}
