.light {
	:global(.ant-tooltip-content) {
		:global(.ant-tooltip-inner) {
			background-color: white !important;
			@apply tw-text-color-border-dark-subtle tw-rounded-lg tw-p-2.5;
		}
	}
	:global(.ant-tooltip-arrow:before) {
		background-color: white !important;
	}
}
