.tag {
	@apply tw-rounded;
	@apply tw-p-1;
	@apply tw-font-medium;
	@apply tw-text-font-size-10;
	@apply tw-leading-3;
	@apply tw-bg-white;
	@apply tw-h-max;
	@apply tw-border-solid;
	border-width: 0.5px;
	&.small {
		@apply tw-py-[2px] tw-px-1;
		@apply tw-text-font-size-10;
		@apply tw-leading-3;
	}
	&.success {
		@apply tw-text-color-green-500;
		@apply tw-bg-color-surface-success;
		@apply tw-border-color-green-200;
	}
	&.blue {
		@apply tw-text-color-blue-500;
		@apply tw-bg-color-blue-50;
		@apply tw-border-color-blue-200;
	}
	&.warning {
		@apply tw-text-color-orange-600;
		@apply tw-bg-color-orange-50;
		@apply tw-border-color-orange-200;
	}
	&.processing {
		@apply tw-text-color-text-weak;
		@apply tw-bg-color-surface-default-hover;
		@apply tw-border-color-gray-200;
	}
	&.bookingConfirmed {
		@apply tw-text-color-text-success;
		@apply tw-bg-color-surface-success;
		@apply tw-border-color-green-200;
	}
	&.failed {
		@apply tw-text-color-text-critical;
		@apply tw-bg-color-surface-critical;
		@apply tw-border-color-red-200;
	}
	&.bookingPending {
		@apply tw-text-color-text-weak;
		@apply tw-bg-color-surface-default-hover;
		@apply tw-border-color-text-weak;
	}
}
