.drawer {
	:global(.ant-drawer-close) {
		@apply tw-hidden;
	}
	:global(.ant-drawer-title) {
		@apply tw-font-medium;
		@apply tw-text-font-size-30;
		@apply tw-leading-5;
		@apply tw-text-color-text-default;
	}
	:global(.ant-drawer-content-wrapper) {
		@apply tw-h-auto;
	}
	:global(.ant-drawer-header) {
		@apply tw-border-b-0;
	}
}
