/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
*/
@tailwind base;

/**
 * This injects Tailwind's component classes and any component classes
 * registered by plugins.
*/
@tailwind components;

/**
 * This injects Tailwind's utility classes and any utility classes registered
 * by plugins.
*/
@tailwind utilities;

/**
 * Use this directive to control where Tailwind injects the hover, focus,
 * responsive, dark mode, and other variants of each class.
 *
 * If omitted, Tailwind will append these classes to the very end of
 * your stylesheet by default.
 */
@tailwind variants;

body {
	margin: 0;
	font-family: 'Inter', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

* {
	box-sizing: border-box;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}
