.ToastContainer {
	[class~='Toastify__toast--error'] {
		@apply tw-bg-color-surface-dark;
		border-radius: 6px;

		[class~='Toastify__toast-body'] {
			@apply tw-text-white;
			@apply tw-typography-body2;
		}

		[class~='Toastify__progress-bar'] {
			background: rgba(255, 255, 255, 0.5);
		}

		[class~='Toastify__close-button'] {
			@apply tw-text-white;
			@apply tw-opacity-100;
			@apply tw-self-center;
		}
	}
}
