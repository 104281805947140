.modalMain {
	&.footerMargintopDisable {
		.ant-modal-footer {
			@apply tw-mt-0;
		}
	}
	.ant-modal-content {
		@apply tw-rounded-xl;
		@apply tw-p-0;
		.ant-modal-header {
			@apply tw-p-4 sm:tw-p-6 tw-mb-0;
			.ant-modal-title {
				@apply tw-font-medium tw-text-font-size-50 tw-leading-7 tw-text-color-surface-dark tw-pr-5;
			}
		}
		.ant-modal-close {
			@apply tw-flex tw-items-end tw-justify-center;
			.ant-modal-close-x {
				@apply tw-flex tw-items-center tw-justify-center;
			}
		}
		.primaryFooter {
			@apply tw-p-6;
			@apply tw-flex tw-justify-end;
			.okBtn,
			.cancelBtn {
				min-width: 92px;
			}
		}
		.secondaryFooter {
			@apply tw-p-6;
			@apply tw-pt-0;
			@apply tw-flex tw-justify-end;
			.okBtn,
			.cancelBtn {
				width: 50%;
			}
		}
		.ant-modal-body {
			max-height: 80vh;
			overflow: auto;
		}
		.modalBody {
			@apply tw-p-6 tw-pt-0;
		}
		.ant-modal-close {
			@apply tw-top-6 tw-right-6 tw-pb-1;
		}
	}
	.ant-btn-primary {
		@apply tw-ms-2;
	}
}
